<template>
  <h1>NestTwo</h1>
</template>

<script>
export default {
name: "NestTwo"
}
</script>

<style scoped>

</style>